<template>
  <promo-code-page-template :page-config="$options.pageConfig"></promo-code-page-template>
</template>

<script>
import PromoCodePageTemplate from '@/components/promo-codes/PromoCodePageTemplate.vue';

const FIELD_NAMES = {
  CODE: 'code',
  GENERATED: 'generated',
  GENERATED_BY: 'generatedBy',
  AMOUNT: 'amount',
  STATUS: 'status',
  ORDERS: 'orders',
  DOCTOR: 'doctorName'
};

const PROMO_CODES_TABLE_HEADER_CONFIG = [
  { text: 'Code', value: FIELD_NAMES.CODE, sortable: false },
  { text: 'Generated', value: FIELD_NAMES.GENERATED, width: '160px' },
  { text: 'Generated by', value: FIELD_NAMES.GENERATED_BY, width: '240px' },
  { text: 'Amount', value: FIELD_NAMES.AMOUNT, width: '160px', sortable: false },
  { text: 'Status', value: FIELD_NAMES.STATUS, width: '240px', sortable: false },
  { text: 'Orders', value: FIELD_NAMES.ORDERS, width: '160px', sortable: false },
  { text: 'For doctor', value: FIELD_NAMES.DOCTOR, sortable: false }
];

const PAGE_CONFIG = {
  pageTitle: 'Seasonal promo codes',
  buttonTitle: 'New seasonal code',
  tableConfig: PROMO_CODES_TABLE_HEADER_CONFIG,
  promoCodeType: 'SEASONAL'
};

export default {
  name: 'SeasonalPromoCodePage',
  components: {
    PromoCodePageTemplate
  },
  pageConfig: PAGE_CONFIG
};
</script>
